import React from "react";
import '/src/assets/css/CollocationServers/collocations-services.css'
import {Link} from "gatsby";

const CollocationsServices = () => {
    return (
        <section id='collocations-services'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-12 text-center">
                        <h3 className='h3-title text-white'>Full-service Collocation Servers</h3>
                        <p className='text-white mb-5'>Enjoy increased performance and redundancy for all your IT equipment with SiteBunker.net’s data center collocation services.</p>

                        <p className='text-white'>Our data center is ready to host any rackable equipment: servers, NAS storage, switches, routers, from 1U to 42U per rack.</p>

                        <p className='text-white'>
                            In addition to the 24/7 access and full support provided by our in-house sysadmin team, you will enjoy the performance of state-of-the-art enterprise-grade technology, including oversized power supply, and even high-performance cooling solutions and ventilation.
                        </p>

                        <p className='text-white'>With us, your entire IT infrastructure will be safe and easy to manage, while the costs of operating the data center will be managed exclusively by SiteBunker.net. This way you can easily enjoy the redundancy and the cost effective solutions.</p>
                    </div>

                    <div className="d-flex justify-content-center w-100">
                        <Link to={'/contact'} className='main-blue-btn w-100 mt-4'>Contact us</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CollocationsServices