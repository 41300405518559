import React from "react";
import '/src/assets/css/CollocationServers/collocation-servers-hero.css'

import checkedImg from '/src/assets/images/collocation-servers/checked-box.png'
import collocationHero from '/src/assets/images/collocation-servers/collocation-servers-hero.png'
import {AnchorLink} from "gatsby-plugin-anchor-links";


const CollocationServersHero = () => {

    return (
        <section id='collocation-servers-hero'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-7 col-12">

                        {/*Header*/}
                        <div className="row">
                            <div className="col-12 mb-4">
                                <h2 className='mb-4 h2-title'>Collocation servers</h2>
                                <p>Are you looking for collocation server services in a high-performance data center with
                                    redundant solutions? SiteBunker.net is at your service!</p>
                            </div>
                        </div>


                        {/*Specs*/}
                        <div className="row mb-5">
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>DataCenter Tier III assured</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Peering over 100Gb/s</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>APC and INFOSC redundant UPS</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>SENCI&ENERGI generator</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>N+1 ventilation and climate</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Biometric security access</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>SLA 99.99% Uptime</span>
                            </div>
                        </div>

                        {/*Buttons*/}
                        <div className="row">
                            <div className="col-6">
                                <AnchorLink
                                    to="/collocation-servers#request-offer"
                                    title="Get offer"
                                    className="main-blue-btn"
                                    stripHash
                                />
                            </div>
                            <div className="col-6">
                                <AnchorLink
                                    to="/collocation-servers#benefits-with-icon"
                                    title="Ask for details"
                                    className="view-more-button"
                                    stripHash
                                />
                            </div>
                        </div>
                    </div>

                    {/*Hero Img*/}
                    <img src={collocationHero} className='img-fluid hero-img' alt="Collocation Hero" loading="lazy"/>

                </div>
            </div>
        </section>
    )
}

export default CollocationServersHero