import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import BenefitsWithIconList from "../components/Common/BenefitsWithIconList";
import CollocationServersHero from "../components/ColocationServers/ColocationServersHero";
import CollocationsServices from "../components/ColocationServers/CollocationsServices";
import RequestOffer from "../components/ColocationServers/RequestOffer";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import SEO from "../components/App/SEO";

// SVG
import DollarSvg from "/src/assets/svgComponents/dolar-circle.svg";
import SaveSvg from "/src/assets/svgComponents/save.svg";
import RefreshServerSvg from "/src/assets/svgComponents/server-refresh.svg";
import SvgChat from "/src/assets/svgComponents/chat.svg"
import SvgInfinite from "/src/assets/svgComponents/inifinite.svg"
import SvgRaks from "/src/assets/svgComponents/raks.svg"
import SvgRound from "/src/assets/svgComponents/round.svg"
import SvgClime from "/src/assets/svgComponents/clime.svg"
import SvgConfig from "/src/assets/svgComponents/config.svg"


const advantagesList = [
    {
        title: 'Cost-efficiency at your service',
        svgImg: <DollarSvg/>,
        description: 'Focus on matters the most and enjoy reduced maintenance & operating costs with the server collocation service offered by SiteBunker.net.'
    },
    {
        title: 'Sysadmin support 24/7',
        svgImg: <SvgChat/>,
        description: 'With more than 16 years IT experience, data transfer and security, the SiteBunker.net sysadmin team is ready to assist you in any technical challenge.'
    },
    {
        title: 'Data Center Tier III assured',
        svgImg: <RefreshServerSvg/>,
        description: 'Enjoy the benefits of an optimized data center, fully equipped with a redundant ventilation system, air conditioning, uninterrupted electricity, and a stable internet connection.'
    },
    {
        title: 'Scalability and synergy',
        svgImg: <SaveSvg/>,
        description: 'Our data center is ready to assist you with tailor-made solutions, sustained by redundant resources. We provide a variety of services to set up your deployment as soon as possible.'
    }
]
const qualitiesList = [
    {
        title: 'Instal and configuration',
        svgImg: <SvgConfig/>,
        description: 'With SiteBunker.net it’s easy to install and configure the collocated server, no matter the size of your project. We are ready to assist you in any challenge.'
    },
    {
        title: '24/7 sysadmin support',
        svgImg: <SvgChat/>,
        description: 'With over 16 years experience, our technicians work with you to ensure a consistent, future-proof environment for your infrastructure.'
    },
    {
        title: 'Electricity and climate control',
        svgImg: <SvgClime/>,
        description: 'Each rack is powered by UPS and a backup generator, but also redundant climate control, assured by smart cooling and ventilation systems.'
    },
    {
        title: 'Stable connectivity',
        svgImg: <SvgInfinite/>,
        description: 'Three European providers are available for a stable connectivity, with peers of over 100 Gb / s and extremely low-latency.'
    },
    {
        title: 'Racks and networking',
        svgImg: <SvgRaks/>,
        description: 'Deploy and connect quickly and reliably, with state-of-the-art CISCO routers, SCHRACK and EATON servers, 42U or 47U racks.'
    },
    {
        title: 'Security and firewall',
        svgImg: <SvgRound/>,
        description: 'Rest assured, your data is safe against DDoS and Flood attacks with the Voxility and Arbor protection, but also permanent packet  traffic analysis.'
    },
    {
        title: 'Request details',
        svgImg: <SvgChat/>,
        description: 'Test our 24/7 availability and send your questions for any collocation server curiosity.'

    }
]
const faqs = [
    {
        title: 'Why should I choose  the server collocation service?',
        text1: 'By collocating a server, you basically decide to host your own server in a fully equipped data center managed by SiteBunker.net, without having to worry again about the secure environment, the power supply or the internet connection, not even the costs with the technical team, climate control and ventilation.',
    },
    {
        title: 'What do I need to do before collocating a server?',
        text1: 'The SiteBunker.net in-house team will inform you about all the details you need to prepare before collocating your servers to our data center. If you wish to speed up deployment, we recommend you to install the operating system on the server and configure the IP so that you can connect to it remotely.',
    },
    {
        title: 'What is the minimum number of servers allowed for collocation?',
        text1: 'You choose the number of servers for collocation at SiteBunker.net. There is no maximum limit. You can start with at least one server.',
    },
    {
        title: 'How do I access the Sitebunker.net data center??',
        text1: 'Access is available 24/7, using the remote system, so you can do any monitoring or administration at any time of the day, from any location by our sysadmins. ' +
            'Also, the SiteBunker.net sysadmin team is available to assist you whenever you need it.',
    },
    {
        title: 'What should you choose?  A dedicated server or a server collocation?',
        text1: 'The difference between a dedicated server and collocation is that in the case of collocation you are the sole owner of the hardware, which gives you total control over the installed operating system and your preferred specifications. In the long run, collocation can be much more advantageous than renting a dedicated hosting server.',
    },
    {
        title: 'I\'m a hosting provider. Can I collocate servers on SiteBunker.net?',
        text1: 'The answer is YES. Even hosting providers can collocate their own servers in the SiteBunker.net data center.',
    },
    {
        title: 'Do you have any extra questions?',
        text1: 'It’s perfectly normal to ask us any additional questions regarding server collocation. You can get in direct contact with the SitBunker.net in-house team at any time of the day or night.',
    }
]


const CollocationServers = () => {
    return (
        <Layout>

            {/*SEO*/}
            <SEO title="Looking for a collocation server? SiteBunker.net is at your service"
                 description="Future-optimised and affordable collocation server is now available at SiteBunker.net’s data center with individual benefits included. Get your offer now!"
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Hero*/}
            <CollocationServersHero/>

            {/*Services*/}
            <CollocationsServices/>

            {/*Advantages*/}
            <BenefitsWithIconList title='Why choose collocation servers?' dataList={advantagesList}
                                  isGreyBackground={false}/>

            {/*Quality Services*/}
            <BenefitsWithIconList title='Collocation servers you can trust'
                                  subTitle='Reliability, performance, and security, without the risk and overhead of building and maintaining your own data center'
                                  dataList={qualitiesList} isGreyBackground={true}/>

            {/*RequestOffer*/}
            <RequestOffer/>

            {/*CollocationFAQS*/}
            <SimpleFAQS title='You ask, we answer to any collocation challenge'
                        subTitle='We have prepared a list of the most frequently asked questions and answers in the section below.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    )
}

export default CollocationServers