import React, {useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik"
import * as Yup from "yup"
import "/src/assets/css/CollocationServers/request-offer.css"
import emailjs from "emailjs-com";
import FormConfirmMessages from "../Common/FormConfirmMessages";


const RequestOffer = () => {


    const [formRequestSend, setFormRequestSend] = useState(false)
    const [responseMsg, setResponseMsg] = useState(false)


    const validate = Yup.object({
        name: Yup.string().required("Required!"),
        email: Yup.string().email("Email is invalid!").required("Required!"),
        specifications: Yup.string().min(10, "Mini 10 characters!").required("Required!")
    })

    function sendContact(formData) {
        emailjs.send("service_274pvw7", "template_dcoqj6j", formData, "B6JiCPyMJA7KanaP3")
            .then((result) => {
                setFormRequestSend(true)
                setResponseMsg(true)

            }, (error) => {
                setFormRequestSend(true)
                setResponseMsg(false)
            })
    }


    return (
        <section id='request-offer'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mb-5">
                        <h3 className='h3-title text-white mb-0'>You ask, we answer to any collocation challenge</h3>
                        <p className='text-white'>We have prepared a list of the most frequently asked questions and answers in the section below.</p>
                    </div>

                    <div className="col-12">
                        <FormConfirmMessages formRequestSend={formRequestSend} responseMsg={responseMsg} />
                    </div>

                    <div className="col-12">
                        <Formik initialValues={{name: "", email: "", company: "", phone: '', specifications: ""}}
                                validationSchema={validate}
                                onSubmit={(data, {setSubmitting, resetForm}) => {
                                    setSubmitting(true)

                                    // Form Send
                                    sendContact(data);

                                    setSubmitting(false)

                                    // Reset Form
                                    resetForm()
                                }}>
                            {({isSubmitting}) => (
                                <Form id="serviceOfferForm">
                                    <div className="row">
                                        <div className="col-md-6 col-12 mb-3">
                                            <div className="form-group">
                                                <Field name="name" type="text" placeholder="Name"
                                                       className="form-control"/>
                                                <ErrorMessage render={msg => <span className="text-danger">{msg}</span>}
                                                              name="name"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12 mb-3">
                                            <div className="form-group">
                                                <Field name="email" type="email" placeholder="Email"
                                                       className="form-control"/>
                                                <ErrorMessage render={msg => <span className="text-danger">{msg}</span>}
                                                              name="email"/>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-12 mb-3">
                                            <div className="form-group">
                                                <Field name="company" type="text" placeholder="Company (optional)"
                                                       className="form-control"/>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-12 mb-3">
                                            <div className="form-group">
                                                <Field name="phone" type="text" placeholder="Phone (optional)"
                                                       className="form-control"/>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <Field name="specifications" placeholder="Specifications"
                                                   className="form-control"
                                                   rows="5" as="textarea"/>
                                            <ErrorMessage render={msg => <span className="text-danger">{msg}</span>}
                                                          name="specifications"/>
                                        </div>
                                        <div className="d-flex justify-content-center w-100 mt-3">
                                            <button disabled={isSubmitting} type="submit"
                                                    className="main-blue-btn w-100">
                                                Contact SiteBunker
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RequestOffer